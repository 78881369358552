import React from 'react'
import styles from "../Styles/LoginStyle.module.css";

function Serviceplan_site({service_data}) {

  const handleCheck = (ele, val) => {
    let arr = ele?.months?.split(",");

    for (let i = 0; i < arr?.length; i++) {
      if (arr[i] == val) {
        return true;
      }
    }
  };

  return (
    <div className="container-fluid">
      {service_data?.map((ele, index) => (
        <div className="row mt-2">
          <div className={`${styles.serviceName} col-3`} >
            <p style={{textAlign:"left"}} className="mt-2">{ele?.category_name}</p>
          </div>
          <div className="col-9">
            <div className="row">
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Jan</span>
                ) : (
                  <></>
                )}
                <div

                  style={{
                    backgroundColor: ele?.January>0  ? "#007cc3" : "white",
                    color: ele?.January>0 ? "white" : "black",
                  }}
                  className={styles.unit1}
                >{ele?.January}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Feb</span>
                ) : (
                  <></>
                )}

                <div
          
                  style={{
                    backgroundColor: ele?.February>0  ? "#007cc3" : "white",
                    color: ele?.February>0 ? "white" : "black",
                  }}
                  className={styles.unit1}
                >{ele.February}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Mar</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.March>0  ? "#007cc3" : "white",
                    color: ele?.March>0 ? "white" : "black",

                  }}
                  className={styles.unit2}
                >{ele.March}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Apr</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.April>0  ? "#007cc3" : "white",
                    color: ele?.April>0 ? "white" : "black",

                  }}
                  className={styles.unit}
                >{ele.April}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>May</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.May>0  ? "#007cc3" : "white",
                    color: ele?.May>0 ? "white" : "black",

                  }}
                  className={styles.unit2}
                >{ele.May}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Jun</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.June>0  ? "#007cc3" : "white",
                    color: ele?.June>0 ? "white" : "black",
                  }}
                  className={styles.unit2}
                >{ele.June}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Jul</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.July>0  ? "#007cc3" : "white",
                    color: ele?.July>0 ? "white" : "black",
                  }}
                  className={styles.unit1}
                >{ele.July}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Aug</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.August>0  ? "#007cc3" : "white",
                    color: ele?.August>0 ? "white" : "black",
                  }}
                  className={styles.unit3}
                >{ele.August}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Sep</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.September>0  ? "#007cc3" : "white",
                    color: ele?.September>0 ? "white" : "black",
                  }}
                  className={styles.unit1}
                >{ele.September}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Oct</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.October>0  ? "#007cc3" : "white",
                    color: ele?.October>0 ? "white" : "black",
                  }}
                  className={styles.unit3}
                >{ele.October}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Nov</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.November>0  ? "#007cc3" : "white",
                    color: ele?.November>0 ? "white" : "black",
                  }}
                  className={styles.unit3}
                >{ele.November}</div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Dec</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: ele?.December>0 ? "#007cc3" : "white",
                    color: ele?.December>0 ? "white" : "black",
                  }}
                  className={styles.unit1}
                >{ele.December}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
      </div>
  )
}

export default Serviceplan_site