import React from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import styles from "../Styles/Table.module.css"

function Invoice_tbl({data}) {
    const columns = [
        {
          name: "invoice_date",
          label: "Invoice date",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (row, data) => {
              if (row == null || row == "") return <></>;
              return (
                <p style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </p>
              );
            },
          },
        },
        {
          name: "invoice_no",
          label: "Invoice No",
          options: {
            filter: true,
            sort: false,
          },
        },
     
        {
          name: "invoive_total",
          label: "Invoice Amount",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row) => {
              return <p>{new Intl.NumberFormat("en-IN").format(row)}</p>;
            },
          },
        },
        {
          name: "payment_due_date",
          label: "due date",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              if (row == null || row == "") return <></>;
              return (
                <p style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </p>
              );
            },
          },
        },
        {
          name: "Payment_status",
          label: "Status",
          options: {
            filter: true,
            sort: false,
          },
        },

      ];
    
      const options = {
        filterType: "checkbox",
      };
  return (
    <div>
            <MUIDataTable
            title={
              <h4 style={{fontSize:"18px",textAlign:"left"}} id={styles.list_head}>
                {/* <DescriptionOutlinedIcon
                  sx={{
                    marginRight: "10px",
                    fontSize: "27px",
                    marginTop: "-2px",
                    color: "#007cc3",
                  }}
                /> */}
                Invoice List
              </h4>
            }
            data={data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              responsive: "standard",
              viewColumns: false,
              print: false,
              download: false,
              search: true,
              filter: true,
              sortOrder: {
                name: "invoice_date",
                direction: "desc",
               },
              rowsPerPageOptions: [10, 25, 50, 100],
              tableBodyMaxHeight: "40vh",
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },

            }}
            
            />
    </div>
  )
}

export default Invoice_tbl