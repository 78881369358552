import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

function Schedule_tbl({
  handleDateRange,
  range_flag,
  selectedFromDate,
  selectedToDate,
  handleDateChange,
  data,
  handleClick,
  display2,
  params_schedule,
}) {

  const storage = window.sessionStorage;
  let site_id = storage.getItem("site_id")

  const [flag,setFlag] = useState("agent_name")

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "time",
      label: "Time",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row, "hh:mm a").format("hh:mm a")}
            </p>
          );
        },
      },
    },
    site_id == "" ?
    {
      name: "site_name",
      label: "Site Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p id={styles.site_name}>{row}</p>
            </div>
          );
        },
      },
    }:
    {
      name: "site_name",
      label: "Site Name",
      options: {
        filter: false,
        sort: false,
        display:false,
        download:false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p id={styles.site_name}>{row}</p>
            </div>
          );
        },
      },
    }
    ,
    {
      name: "agent_name",
      label: "Agent",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row,data) => {
        //  if(data?.rowData[4] == "Mark Done"){
        //   setFlag("food_tech_name")
        //  }else{
        //   setFlag("agent_name")
        //  }
          if(row == "" || row == null){
            return<>---</>
          }
          return <p style={{ padding: "7px" }}>{row == "Superadmin"?"Equinox":row}</p>;
        },
      },
    },
    {
      name: "new_vr_status",
      label: "Status",
      options: {
        filter: true,
        sort: false,

        
        customBodyRender: (row,data) => {
          let schedule_date = moment(data?.rowData[0]).format("MM-DD-YYYY");
          let current_date = moment(new Date()).format("MM-DD-YYYY");
          let date1 = new Date(schedule_date).getTime();
          let date2 = new Date(current_date).getTime();
          if(date2>date1 && row == "Scheduled"){
            return  <p
            style={{
              color: "red"
            }}
          >
           Missed
          </p>
          }
          return (
            <p
              style={{
                color: row == "Postponed By Scheduler" ? "red" : "#007cc3",
              }}
            >
              {row == "Postponed By Scheduler" ? "Missed" : row}
            </p>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  //   if (data == null) {
  //     return (
  //       <>
  //         <img src={nullData} alt="" id={styles.nullGif} />
  //       </>
  //     );
  //   }
  return (
    <div style={{marginBottom:"30px"}} id={styles.mainTableDiv}>
      <MUIDataTable
        title={
          <h4
            style={{ fontSize: "18px", textAlign: "left" }}
            id={styles.list_head}
          >
            Active Schedules
          </h4>
        }
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: true,
          // filter: false,
          pagination: false,
          rowsPerPageOptions: false,
          tableBodyMaxHeight: "46vh",
          sortOrder: {
            name: "date",
            direction: "desc",
           },
          customToolbar: () => {
            return (
              <>
                <div style={{ display: "inline-flex" }}>
                  <select
                    onChange={handleDateRange}
                    value={params_schedule}
                    className="form-select"
                    style={{ width: "160px" }}
                  >
                    <option value="" selected >
                      All
                    </option>
                    {/* <option value="" selected disabled>
                      Please Select
                    </option> */}
                    <option value="today">Today</option>
                    <option value="month_till_date">Month Till Date</option>
                    <option value="quarter_till_date">Quarter Till Date</option>
                    <option value="last_quarter">Last Quarter</option>
                    <option value="last_six_month">Last Six Month</option>
                    <option value="custom">Custom</option>
                  </select>
                  {range_flag ? (
                    <>
                      <RangePicker
                        // defaultValue={dayjs(value, "YYYY-MM-DD")} format={dateFormat}
                        format={"DD-MM-YYYY"}
                        value={[selectedFromDate, selectedToDate]}
                        onChange={(new_val) => handleDateChange(new_val)}
                      />
                      <button
                        className="btn btn-primary btn-sm ml-2"
                        onClick={handleClick}
                        style={{
                          display: display2 ? "flex" : "none",
                          marginLeft: "5px",
                          //   color:"#007cc3"
                        }}
                      >
                        Apply
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          },
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
        }}
      />
    </div>
  );
}

export default Schedule_tbl;
