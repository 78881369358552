import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Drawer_customer from "../Components/Drawer";
import styles from "../Styles/Service_avails.module.css";
import loader_img from "../Media/Business_chart.gif"


import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../Components/footer";

function Service_on_customer({base_url}) {
    const storage = window.sessionStorage;
    let profile = JSON.parse(storage.getItem("profile"));
    let customer_id = storage.getItem("customer_id");
    let site_id = storage.getItem("site_id");
    let token = storage.getItem("token");
    let customer_name = storage.getItem("customer_name");
    const [params, setParams] = useState("");

    let support_details = JSON?.parse(storage.getItem("support_details"));
    const [year_heat_map, setYear_Heat_Map] = useState(new Date());
    let info_arr = year_heat_map?.toString()?.split(" ");
  
    const [service_data, setService_Data] = useState([]);
    const [service_header, setService_Header] = useState([]);
    const [heat_map_load, setHeat_Map_Load] = useState(false);

   const [total_count,setTotal_Count] = useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);



    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - service_data?.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const get_service_data = async (val) => {
        setHeat_Map_Load(true);
        let token = storage.getItem("token");
        axios.defaults.headers.common = {
          Authorization: "Bearer " + token?.replace('"', ""),
        };
        const formData = new FormData();
    
        formData.append("customer_id", customer_id);
        axios({
          url: `${base_url}/customer-service-heatmap`,
          method: "POST",
          data: formData,
        })
          .then((res) => {
            setHeat_Map_Load(false);
            if (res?.data?.code == "200") {
              // console.log(res?.data?.data[0])
              setService_Data(res?.data?.data[0]);
              setService_Header(res?.data?.data[1]);
              setTotal_Count(res?.data?.data[2]);   
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
    
              Toast.fire({
                icon: "error",
                title: res?.data?.message,
              });
            }
          })
          .catch((err) => {
            setHeat_Map_Load(false);
            setService_Data([]);
            setService_Header([]);
          });
      };

    useEffect(()=>{
      if(token != "" && token != null){
        get_service_data();
      }
    },[])

  return (
    <>
    <Drawer_customer/>
  {heat_map_load?<div>
    <img src={loader_img} id={styles.loader}/>
  </div>:  <div className='container-fluid' style={{padingLeft:"5em",paddingRight:"1em"}}>
    <div className="row" style={{paddingLeft:"5em"}}>
          <Paper
            sx={{
              // width: index == 0 ? "40%" : "20%",
              // width: "94%",
              mb: 2,
              padding: "0px",
              // marginLeft:"5%"
              // height: "500px",
            }}
          >
            <TableContainer  id={styles.service_tbl}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  sx={{
                    backgroundColor: "#007cc3",
                    color: "#444a4f !important",
                    position: "sticky",
                    top: 0,
                    zIndex: "999",
                  }}
                >
                  <TableRow>
                    <TableCell
                      id={styles.th_unit1}
                      style={{ borderBottom: "1px solid #89BBE0" }}
                      colspan={40}
                    >
                      <h5
                        className="ml-2 mt-2"
                        style={{
                          color: "white",
                          textAlign: "left",
                          marginLeft: "1%",
                        }}
                      >
                        {" "}
                        Services
                      </h5>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {/* <TableCell
                  id={styles.th_unit1}
                  style={{ borderTop: "1px solid #89BBE0" }}
                >
                  {" "}
                </TableCell> */}
                    <TableCell id={styles.th_unit1} >
                      <h6 className="mt-2" style={{ color: "white" }}>
                        Site Name
                      </h6>
                    </TableCell>
        
                    <TableCell id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        Service Type
                      </h6>
                    </TableCell>
                    <TableCell

                      id={styles.th_unit1}
                      hidden={total_count?.Air_Corrosion_Testing_total == "0"?"hidden":""}
                    >
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[0]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                     hidden={total_count?.Ambient_Air_Quality_Monitoring_8Hrs_total == "0"?"hidden":""}
                      id={styles.th_unit1}
                    >
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[1]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                        hidden={total_count?.Ambient_Air_Quality_Testing_total == "0"?"hidden":""}
                      id={styles.th_unit1}
                    >
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[2]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                               hidden={total_count?.Ambient_Noise_8_Hrs_total == "0"?"hidden":""}
                      id={styles.th_unit1}
                    >
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[3]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                     hidden={total_count?.Beverages_Testing_total == "0"?"hidden":""}
  
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[4]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                      hidden={total_count?.Boiler_Stack_Testing_total == "0"?"hidden":""}
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[5]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                           hidden={total_count?.Compressed_Air_Testing_total == "0"?"hidden":""}
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[6]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                        hidden={total_count?.Continuous_Ambient_Noise_24_hrs_Testing_total == "0"?"hidden":""}
    
                      id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[7]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                      hidden={total_count?.DG_stack_Testing_total == "0"?"hidden":""}
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[8]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                                   hidden={total_count?.Effluent_Water_Testing_total == "0"?"hidden":""}
    
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[9]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                           hidden={total_count?.Environment_Testing_total == "0"?"hidden":""}
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[10]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                               hidden={total_count?.Food_Chemical_Testing_total == "0"?"hidden":""}
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[11]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
hidden={total_count?.Food_Microbiology_Testing_total == "0"?"hidden":""}
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[12]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                    hidden={total_count?.Food_Testing_total == "0"?"hidden":""}
                       id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[13]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                         hidden={total_count?.Fuel_Testing_total == "0"?"hidden":""}
                     id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[14]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
        hidden={total_count?.Furnace_Stack_Testing_total == "0"?"hidden":""}
                      id={styles.th_unit1}
                    >
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[15]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                hidden={total_count?.Hourly_Ambient_Noise_24_Testing_total == "0"?"hidden":""}
                      id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[16]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                     hidden={total_count?.Incinerator_Stack_Testing_total == "0"?"hidden":""}
                      id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[17]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
            hidden={total_count?.Indoor_Air_Quality_Testing_total == "0"?"hidden":""}
                      id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[18]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                       hidden={total_count?.Light_Intensity_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[19]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                      hidden={total_count?.Migration_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[20]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                        hidden={total_count?.Noise_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[21]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                       hidden={total_count?.Nutritional_Label_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[22]?.name}
                      </h6>
                    </TableCell>
                    <TableCell  
                       hidden={total_count?.Others_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[23]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                      hidden={total_count?.Shelf_Life_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[24]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                            hidden={total_count?.Soil_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[25]?.name}
                      </h6>
                    </TableCell>
                    <TableCell  
                       hidden={total_count?.Spot_Noise_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[26]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                       hidden={total_count?.Swab_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[27]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                      hidden={total_count?.Water_Testing_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[28]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                         hidden={total_count?.Work_Place_Monitoring_total == "0"?"hidden":""}
                    id={styles.th_unit1}>
                      <h6 className="mt-2" style={{ color: "white" }}>
                        {service_header[29]?.name}
                      </h6>
                    </TableCell>
             
                  </TableRow>
                </TableHead>
                {service_data?.length == "0" ? (
              <TableBody>
                {" "}
                <TableRow>
                  <TableCell colSpan={11} className={styles.th_unit}>
                    <p style={{ textAlign: "center" }}>No Data Available</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody id={styles.body_content}>
                {(rowsPerPage > 0
                  ? service_data?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : service_data
                )?.map((ele) => (
                  <TableRow rowSpan={3}>
                    <TableCell className={styles.th_unit} >
                      <p className="p mt-2" style={{ textAlign: "left",fontWeight:"bold" }}>
                        {ele.site_name}
                      </p>
                    </TableCell>
                     <TableCell  >
                     <TableRow><TableCell  className={styles.th_unit}><p style={{ textAlign: "left",fontWeight:"500" }} className="p mt-2">Planned</p></TableCell></TableRow>
                     <TableRow><TableCell  className={styles.th_unit}><p style={{ textAlign: "left",fontWeight:"500" }} className="p mt-2">Scheduled</p></TableCell></TableRow>
                     <TableRow><TableCell  className={styles.th_bottom}><p style={{ textAlign: "left",fontWeight:"500" }} className="p mt-2">Collected</p></TableCell></TableRow>

                     </TableCell>
                    <TableCell   hidden={total_count?.Air_Corrosion_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Air_Corrosion_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Air_Corrosion_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Air_Corrosion_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell  hidden={total_count?.Ambient_Air_Quality_Monitoring_8Hrs_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Ambient_Air_Quality_Monitoring_8Hrs_planned == undefined?0:ele?.Ambient_Air_Quality_Monitoring_8Hrs_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Ambient_Air_Quality_Monitoring_8Hrs_scheduled == undefined ? 0:ele?.Ambient_Air_Quality_Monitoring_8Hrs_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Ambient_Air_Quality_Monitoring_8Hrs_collected == undefined? 0: ele?.Ambient_Air_Quality_Monitoring_8Hrs_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Ambient_Air_Quality_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Ambient_Air_Quality_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Ambient_Air_Quality_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Ambient_Air_Quality_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Ambient_Noise_8_Hrs_total == "0"?"hidden":""}>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Ambient_Noise_8_Hrs_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Ambient_Noise_8_Hrs_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Ambient_Noise_8_Hrs_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell hidden={total_count?.Beverages_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Beverages_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Beverages_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Beverages_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Boiler_Stack_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Boiler_Stack_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Boiler_Stack_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Boiler_Stack_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell hidden={total_count?.Compressed_Air_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Compressed_Air_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Compressed_Air_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Compressed_Air_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>               
                    <TableCell hidden={total_count?.Continuous_Ambient_Noise_24_hrs_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Continuous_Ambient_Noise_24_hrs_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Continuous_Ambient_Noise_24_hrs_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Continuous_Ambient_Noise_24_hrs_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.DG_stack_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.DG_stack_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.DG_stack_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.DG_stack_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Effluent_Water_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Effluent_Water_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Effluent_Water_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Effluent_Water_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Environment_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Environment_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Environment_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Environment_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Food_Chemical_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Food_Chemical_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Food_Chemical_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Food_Chemical_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Food_Microbiology_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Food_Microbiology_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Food_Microbiology_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Food_Microbiology_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Food_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Food_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Food_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Food_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Fuel_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Fuel_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Fuel_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Fuel_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Furnace_Stack_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Furnace_Stack_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Furnace_Stack_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Furnace_Stack_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Hourly_Ambient_Noise_24_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Hourly_Ambient_Noise_24_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Hourly_Ambient_Noise_24_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Hourly_Ambient_Noise_24_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Incinerator_Stack_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Incinerator_Stack_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Incinerator_Stack_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Incinerator_Stack_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
           
                    <TableCell hidden={total_count?.Indoor_Air_Quality_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Indoor_Air_Quality_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Indoor_Air_Quality_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Indoor_Air_Quality_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell hidden={total_count?.Light_Intensity_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Light_Intensity_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Light_Intensity_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Light_Intensity_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
   
           
                    <TableCell hidden={total_count?.Migration_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Migration_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Migration_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Migration_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell hidden={total_count?.Noise_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Noise_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Noise_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Noise_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell hidden={total_count?.Nutritional_Label_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Nutritional_Label_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Nutritional_Label_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Nutritional_Label_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell hidden={total_count?.Others_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Others_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Others_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Others_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Shelf_Life_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Shelf_Life_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Shelf_Life_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Shelf_Life_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Soil_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Soil_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Soil_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Soil_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Spot_Noise_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Spot_Noise_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Spot_Noise_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Spot_Noise_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>
                    <TableCell hidden={total_count?.Swab_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Swab_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Swab_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Swab_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell  hidden={total_count?.Water_Testing_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Water_Testing_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Water_Testing_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Water_Testing_collected}</p></TableCell></TableRow>
                    </TableCell>

                    <TableCell hidden={total_count?.Work_Place_Monitoring_total == "0"?"hidden":""} >
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Work_Place_Monitoring_planned}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_unit}><p className="p mt-2">{ele?.Work_Place_Monitoring_scheduled}</p></TableCell></TableRow>
                    <TableRow><TableCell  className={styles.th_bottom}><p className="p mt-2">{ele?.Work_Place_Monitoring_collected}</p></TableCell></TableRow>
                    </TableCell>
           
                  
                   
                    
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
              </Table>
            </TableContainer>
            <TablePagination
              id={styles.footer_div}
              class="footer_div"
              sx={{ backgroundColor: "#007cc3", color: "white" }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={service_data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
    </div>}

    {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
             
                    <p id={styles.copyright}>
                   

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
  
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}
          <Footer/>
    </>
  )
}

export default Service_on_customer