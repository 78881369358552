import React from 'react'

function CustomersList() {
  return (
    <>
    <div>
        Customer List
    </div>
    </>
  )
}

export default CustomersList