import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Pages/Dashboard'
import Sites from '../Pages/Sites'
import Purchase_order from '../Pages/Purchase_order'
import Invoice from '../Pages/Invoice'
import LoginPage from "../Pages/LoginPage"
import CustomersList from '../Pages/CustomersList'
import AccountsList from '../Pages/AccountsList'
import Download_report from '../Pages/Download_report'
import SuperAdmin from '../Components/Minidrawer'
import Services from '../Pages/Services'
import Payments from '../Pages/Payments'
import Site_Page from '../Pages/Site_Page'
import Query_list from '../Pages/Query_list'
import Services_avails from '../Pages/Services_avails'
import Service_on_customer from '../Pages/Service_on_customer'

function Allroutes() {
  let base_url = "http://beta-hub.equinoxlab.com/api"
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage/> }/>
        <Route path="/customerList" element={<CustomersList /> }/>
        <Route path="/dashboard" element={<Dashboard base_url={base_url} />}/>
        <Route path="/sites" element={<Sites/>}/>
        <Route path="/purchase_order" element={<Purchase_order base_url={base_url}/>}/>
        <Route path="/invoices" element={<Invoice/>}/>
        <Route path="/accountsList" element={<AccountsList base_url={base_url} />} />
        <Route path="/download_report" element={<Download_report base_url={base_url} />} />
        <Route path="/services" element={<SuperAdmin />} />
        <Route path="/service_avail" element={<Service_on_customer base_url={base_url} />} />
        {/* <Route path="/service_avail" element={<Services base_url={base_url} />} /> */}
        <Route path="/payments" element={<Payments base_url={base_url} />} />
        <Route path="/site_page" element={<Site_Page base_url={base_url} />} />
        <Route path="/query_list" element={<Query_list base_url={base_url} />} />
      </Routes>
    </div>
  )
}

export default Allroutes