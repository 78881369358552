import logo from './logo.svg';
import './App.css';
import Allroutes from './Allroutes/Allroutes';

function App() {
  return (
    <div className="App">
    {/* <div className="App" style={{marginTop:"4em",marginLeft:"4em"}}> */}
          <Allroutes/>
    </div>
  );
}

export default App;
 