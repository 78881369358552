import React from "react";
import styles from "../Styles/Dashboard.module.css";

function Single_tab({ ele, selected_flag, setSelected_Flag, setSample_Name, setFile_Path,get_report_list }) {
  const handleClick = (id) => {
    setSample_Name(ele?.name);
    setSelected_Flag(id);
    setFile_Path("")
    get_report_list(ele?.name)
  };
  return (
    <div
    className="col-2"
      id={styles.sample_unit}
      style={{ backgroundColor: selected_flag == ele.id ? "#007cc3" : "white", display: ele?.total_count==0?"none":"flex" }}
      onClick={() => handleClick(ele?.id)}
    >
      {/* <div> */}
      <span style={{ color: selected_flag == ele.id ? "white" : "black" }}>
        {ele?.name}
      </span>
      {/* </div> */}
      <div
        style={{
          backgroundColor: selected_flag == ele.id ? "white" : "red",
          color: selected_flag == ele.id ? "red" : "white",
          padding:"13px"
        }}
      >
      <span>{ele?.total_count}</span>  
      </div>
    </div>
  );
}

export default Single_tab;
