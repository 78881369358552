import React from "react";
import styles from "../Styles/Table.module.css";
import MUIDataTable from "mui-datatables";
import { saveAs } from "file-saver";
import moment from "moment";

function Collection_tbl({ data, setFile_Path, selected_flag }) {
  const handleClick = (row) => {
    //   (async () => {
    //     let blob = await fetch(row).then((r) => r.blob());
    //     saveAs(blob);
    //  })();
    saveAs(row);
  };

  const handleRow = (data_row) => {

    setFile_Path(data_row[0][0]?.url);
  };
  const columns = [
    {
      name: "reports_files",
      label: "reports_files",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "date_of_collection",
      label: "Date Of Collection",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "description",
      label: "Sample Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "source_of_sample",
      label: "Source",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "10px" }}>{row}</p>;
        },
      },
    },
    {
      name: "date_of_report",
      label: "Date Of Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "report_sent_date",
      label: "Report Sent Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "reports_files",
      label: "reports files",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <>
              <div className={styles.dropdown}>
                <span style={{ color: "#007cc3" }}>View</span>
                <div className={styles.dropdown_content}>
                  {row?.map((ele, index) => (
                    <div>
                      <span>file{index + 1}</span>
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                        style={{
                          height: "20px",
                          marginLeft: "10%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleClick(ele?.url)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <div>
      <MUIDataTable
        // title={
        //   <h4 id={styles.list_head}>
        //     Collection Table
        //   </h4>
        // }
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: false,
          filter: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          onRowClick: (rowData) => {
            handleRow(rowData);
          },

          sortOrder: {
            name: "date_of_collection",
            direction: "desc",
          },
          setRowProps: () => ({
            style: {
              cursor: "pointer",
            },
          }),
          textLabels: {
            body: {
              noMatch:
                selected_flag == ""
                  ? "Please Select tab to get Report List"
                  : "Data Not Available",
            },
          },
        }}
      />
    </div>
  );
}

export default Collection_tbl;
