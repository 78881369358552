import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import styles from "../Styles/LoginStyle.module.css"

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import AccountCircle from '@mui/icons-material/AccountCircle';
import { useState } from "react";
import Purchase_order from "../Pages/Purchase_order";
import Invoice from "../Pages/Invoice";



import DescriptionIcon from '@mui/icons-material/Description';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalMallIcon from '@mui/icons-material/LocalMall';

import equinox_logo from "../Media/equinox_logo.png"
import Payments from "../Pages/Payments";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SuperAdmin({ header_name }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
   
  const storage = window.sessionStorage;
  let token = storage.getItem("token")
  let customer_name = storage.getItem("customer_name")

  const navigate = useNavigate();


  const [org, setOrg] = React.useState(true);
  const [invoices, setInvoices] = React.useState(true);
  const [payments, setPayments] = React.useState(false);
  const [purchase, setPurchase] = React.useState(false);


  const [change1, setChange1] = React.useState(false);
  const [change2, setChange2] = React.useState(false);
  const [change3, setChange3] = React.useState(false);
  const [change4, setChange4] = React.useState(false);
  const [change5, setChange5] = React.useState(false);
  const [change6, setChange6] = React.useState(false);
  const [change7, setChange7] = React.useState(false);
  const [change8, setChange8] = React.useState(false);
  const [change9, setChange9] = React.useState(false);

  const [sales_show, setSales_Show] = useState(false);
  const [marketing_show, setMarketing_Show] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick1 = () => {
    setInvoices(true);
    setPayments(false);
    setPurchase(false);
  };
  const handleClick2 = () => {
    setInvoices(false);
    setPayments(true);
    setPurchase(false);
  };
  const handleClick3 = () => {
    setInvoices(false);
    setPayments(false);
    setPurchase(true);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleLogout = () => {

  };



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSales_Show(false)
    setMarketing_Show(false)
  };

  React.useEffect(()=>{
     let flag_check = storage.getItem("flag_page");
     if(flag_check == "/purchase_order"){
       setPurchase(true)
       setInvoices(false)
       setPayments(false)
      }
else if(flag_check == "/invoices"){
  setPurchase(false)
  setInvoices(true)
  setPayments(false)
     }else{
      let x = 1;
     }
storage.setItem("flag_page","")

  },[])

  const handleSwitch = ()=>{
    navigate("/accountsList")
    handleClose();
  }


  const handleSignout = ()=>{
    storage.setItem('count',"")
    storage.setItem('customer_id',"")
    storage.setItem('customer_name',"")
    storage.setItem('site_length',"")
    storage.setItem('flag_page',"")
    storage.setItem('login_mobile_no',"")
    storage.setItem('token',"")
    handleClose();
    window.location.reload();
  }
  
  
    React.useEffect(()=>{
      if(token == "" || token == null){
        navigate("/")
      }
    },[storage])

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{
        // background: "transparent"
        backgroundColor:"white"
        }} open={open}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // background: "transparent",
            backgroundColor: "white",
          }}
        >
          <Box sx={{ display: "flex",
          // ,background: "transparent"
          backgroundColor:"white"
          }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
                backgroundColor:"#f8f8fa",
               
              }}
            >
              <MenuIcon sx={{color:"#007cc3",backgroundColor:"#f8f8fa"}} />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ marginTop: "1.8% !important",color:"black" }}
            >
              {customer_name}
            </Typography>
          </Box>
          {/* <Box
            sx={{
              marginLeft: "70%",
              display: "flex",
              marginTop: "5px",
              gap: "10px",
              alignItems: "center",
            }}
          > */}
          {/* <Tooltip title="Sign Out" placement="left">
            <div
              style={{
                marginLeft: "0px",
                color: "white",
                background: "linear-gradient(-45deg, #3db3c5, #274685)",
                height: "30px",
                width: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={handleLogout}
            >
              <PowerSettingsNewIcon sx={{ height: "20px" }} />
            </div>
          </Tooltip> */}
          <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle sx={{color:"black"}} />
              </IconButton>
          <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
  
      open={Boolean(anchorEl)}
      onClose={handleClose}

    >
      <MenuItem  onClick={handleClose}>Organisation Details</MenuItem>
      <MenuItem  onClick={handleClose}>Profile</MenuItem>
      <MenuItem  onClick={handleClose}>Invoices</MenuItem>
      <MenuItem  onClick={handleSwitch}>Switch Account</MenuItem>
      <MenuItem sx={{justifyContent:"center",color:"#007cc3"}} onClick={handleSignout}>Signout</MenuItem>
    </Menu>
          {/* </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        // onMouseLeave={handleDrawerClose}
        // onMouseOver={handleDrawerOpen}
        sx={{background:"transparent"}}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
          <img
              style={{
                height: "40px",
                width: "auto",
                objectFit: "contain",
                marginRight: "35px",
              }}
              onClick={() => navigate("/dashboard")}
              // src="../images/EQ_logo_New_tagline_white.png"
              src={equinox_logo}
            />
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{color:"#007cc3"}} />
            ) : (
              <ChevronLeftIcon sx={{color:"#007cc3"}} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List >

        <ListItem
                  disablePadding
                  sx={{ display: "block" , background:"transparent" }}
                  onClick={handleClick1}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                    {open?<DescriptionIcon sx={{ color: "#007cc3" }} />:<></>}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Invoices"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: change7 ? "#007cc3" : "black",
                      }}
                      // onMouseEnter={() => setChange7(true)}
                      // onMouseLeave={() => setChange7(false)}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  disablePadding
                  sx={{ display: "block", background:"transparent" }}
                  onClick={handleClick2}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {open?<PaymentIcon sx={{ color: "#007cc3" }} />:<></>}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Payments"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: change7 ? "#007cc3" : "black",
                      }}
                      // onMouseEnter={() => setChange7(true)}
                      // onMouseLeave={() => setChange7(false)}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  disablePadding
                  sx={{ display: "block", background:"transparent" }}
                  onClick={handleClick3}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {open?<LocalMallIcon sx={{ color: "#007cc3" }} />:<></>}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Purchase Orders"}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: change7 ? "#007cc3" : "black",
                      }}
                      // onMouseEnter={() => setChange7(true)}
                      // onMouseLeave={() => setChange7(false)}
                    />
                  </ListItemButton>
                </ListItem>
       
        </List>
      </Drawer>
      
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3}}
      >
        <DrawerHeader />
      {purchase?<Purchase_order/>:<></>}
      {invoices?<Invoice/>:<></>}
      {payments?<Payments/>:<></>}
      <div style={{position:"absolute", bottom:"0px", left:"0px", right:"0px"}}>
                  <p id={styles.copyright}>
                  Copyright : Equinox Labs Pvt ltd-2023
                  </p>
              </div>
      </Box>
    </Box>
  );
}
