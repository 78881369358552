import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import loader_img from "../Media/Business_chart.gif"
import styles from "../Styles/Modals.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

function Service_details({ open, handleOpen, handleClose, data ,details_load}) {
  const storage = window.sessionStorage;
  const profile = JSON.parse(storage.getItem("profile"));
   console.log(data)
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Service Details
              </h1>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button> */}
            </div>
          {details_load? <div>
            <img id={styles.loader} src={loader_img}/>
          </div>:  <div className="modal-body mt-4" id={styles.service_modal}>
{data?.map((ele)=>(
    // <p><span>{ele?.testing_service_name}</span> : {ele?.collection_count}</p>
    <div id={styles.tab_contain}>
      <span>{ele?.testing_service_name}</span> 
      <div>
        <span>{ele?.collection_count}</span>
      </div>
    </div>
))}
            </div>}
            <div className="modal-footer mt-2">
              <button
                type="button"
                className="btn btn-secondary"
                // data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Service_details;
