import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import styles from "../Styles/LoginStyle.module.css";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import Purchase_order from "../Pages/Purchase_order";
import Invoice from "../Pages/Invoice";

import DescriptionIcon from "@mui/icons-material/Description";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalMallIcon from "@mui/icons-material/LocalMall";

import equinox_logo from "../Media/equinox_logo.png";
import Payments from "../Pages/Payments";

import HomeIcon from "@mui/icons-material/Home";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PaymentsIcon from "@mui/icons-material/Payments";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EventNoteIcon from "@mui/icons-material/EventNote";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ContactPhoneSharpIcon from '@mui/icons-material/ContactPhoneSharp'; 
import Add_query from "../Modals/Add_query";
import Org_details from "../Modals/Org_details";
import Swal from "sweetalert2";
import axios from "axios";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Drawer_customer({query_check}) {
  const theme = useTheme();

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open, setOpen] = React.useState(false);

  const [profile_data,setProfile_Data] = useState([])
  const [support_data,setSupport_Data] = useState([])

  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);



  const storage = window.sessionStorage;
  let token = storage.getItem("token");
  let customer_name = storage.getItem("customer_name");
  let customer_id = storage.getItem("customer_id");

  let base_url = "http://beta-hub.equinoxlab.com/api"

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const [hover_data,setHover_Data] = useState([])

  function handleClick(event) {
    if (anchorEl2 !== event.currentTarget) {
      setAnchorEl2(event.currentTarget);
    }
  }

  function handleClick_sales(event) {
    if (anchorEl3 !== event.currentTarget) {
      setAnchorEl3(event.currentTarget);
    }
  }

  function handleClose_kae() {
    setAnchorEl2(null);
  }

  function handleClose_sales() {
    setAnchorEl3(null);
  }




  const handleClick1 = () => {
    setOpen(false);
  };
  const handleClick2 = () => {
    // setOpen(true);
  };
  const handleClick3 = () => {};

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setshow(false);
    setshow2(false);
    setshow3(false);
    setOpen(false);
  };

  React.useEffect(() => {}, []);

  const handleSwitch = () => {
    navigate("/accountsList");
    handleClose();
  };

  const handleOrg = ()=>{
    handleOpen3();
    handleClose();
  }

  const handleSignout = () => {
    storage.setItem("count", "");
    storage.setItem("customer_id", "");
    storage.setItem("customer_name", "");
    storage.setItem("site_length", "");
    storage.setItem("flag_page", "");
    storage.setItem("login_mobile_no", "");
    storage.setItem("token", "");
    handleClose();
    window.location.reload();
  };

  const handlePath = (e, path) => {
    if (e.ctrlKey) {
      if (path == "/service_avail" || path == "/dashboard") {
        storage.setItem("site_id", "");
      }
      window.open(path, "_blank");
    } else {
      if (path == "/service_avail"|| path == "/dashboard") {
        storage.setItem("site_id", "");
      }
      navigate(path);
    }
    // if (e.ctrlKey) {
    //     if(val == "/sites" || val == "/service_avail" || val == "/dashboard"){
    //       if(val == "/service_avail"){
    //         storage.setItem("site_id","")
    //       }
    //         window.open(val, "_blank");
    //     }else{
    //         storage.setItem("flag_page",val)
    //         window.open("services","_blank")
    //     }
    //   } else {
    //     if(val == "/sites" || val == "/service_avail"|| val == "/dashboard"){
    //       if(val == "/service_avail"){
    //         storage.setItem("site_id","")
    //       }
    //       navigate(val)
    //     }else{
    //         storage.setItem("flag_page",val)
    //         navigate("/services")
    //     }
    //   }
  };

  const get_profile_details = async() =>{
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    // formData.append("year", year == ""?2024:Number(year));
    axios({
      url: `${base_url}/customer-profile-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        if (res?.data?.code == "200") {
          // setSchedule_Heat_Map_Data(res?.data?.data);
          setProfile_Data(res?.data?.data);
          // setProfile_Data
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const get_support_details = async() =>{
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    // formData.append("year", year == ""?2024:Number(year));
    axios({
      url: `${base_url}/customer-support-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        if (res?.data?.code == "200") {
          // setSchedule_Heat_Map_Data(res?.data?.data);
       

          setSupport_Data(res?.data?.data);
          storage.setItem("support_details",JSON.stringify(res?.data?.data[0]))
          // setProfile_Data
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }



  React.useEffect(() => {
    if (token == "" || token == null) {
      navigate("/");
    }
  }, [storage]);


  React.useEffect(()=>{
    if(customer_id != "" || customer_id!= null){
      get_profile_details();
      get_support_details();
    }
  },[])

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ background: "transparent" }} open={open}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
                backgroundColor: "#f8f8fa",
              }}
            >
              <MenuIcon sx={{ color: "#007cc3", backgroundColor: "#f8f8fa" }} />
            </IconButton>
            {/* <Typography
              variant="h6"
              noWrap
              // component="div"
              sx={{textAlign:"right",color:"black" }}
            >
              {customer_name}
            </Typography> */}
          </Box>
          {/* <Box
            sx={{
              marginLeft: "70%",
              display: "flex",
              marginTop: "5px",
              gap: "10px",
              alignItems: "center",
            }}
          > */}
          {/* <Tooltip title="Sign Out" placement="left">
            <div
              style={{
                marginLeft: "0px",
                color: "white",
                background: "linear-gradient(-45deg, #3db3c5, #274685)",
                height: "30px",
                width: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={handleLogout}
            >
              <PowerSettingsNewIcon sx={{ height: "20px" }} />
            </div>
          </Tooltip> ,width:"400px"*/}

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* <div className="col-sm-12 col-md-6"> */}
            <p
              style={{
                textAlign: "right",
                width: "400px",
                color: "black",
                marginTop: "10px",
                marginRight: "5px",
                marginBottom: "10px",
                lineHeight: "18px",
                fontSize: "16px",
              }}
            >
              {customer_name}
            </p>
            {/* </div> */}

            {/* <div className="col-sm-12 col-md-1"> */}
            <p id={styles.middleLine}></p>
            {/* </div> */}

            {/* <div className="col-sm-12 col-md-4"> */}
            <div id={styles.btn_container}>
              <button onClick={handleOpen2} className="btn">
                Raise Query
              </button>
            </div>

            {/* </div> */}

            {/* <div className="col-sm-12 col-md-1"> */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{
                background: "#007cc3",
                border: "1px solid white",
                height: "30px",
                width: "30px",
                marginTop: "-5px",
              }}
            >
              <AccountCircle sx={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            {/* </div> */}
          </div>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOrg}>Organisation Details</MenuItem>
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>Invoices</MenuItem> */}
            <MenuItem onClick={handleSwitch}>Switch Account</MenuItem>
            <MenuItem
              sx={{ justifyContent: "center", color: "#007cc3" }}
              onClick={handleSignout}
            >
              Signout
            </MenuItem>
          </Menu>
          {/* </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        // onMouseLeave={handleDrawerClose}
        // onMouseOver={handleDrawerOpen}
        // sx={{background:"transparent"}}
      >
        <DrawerHeader>
          <img
            style={{
              height: "40px",
              width: "auto",
              objectFit: "contain",
              marginRight: "35px",
              cursor: "pointer",
            }}
            onClick={(e) => handlePath(e, "/dashboard")}
            // src="../images/EQ_logo_New_tagline_white.png"
            src={equinox_logo}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "#007cc3" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "#007cc3" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={(e) => handlePath(e, "/dashboard")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {open ? (
                  <HomeIcon sx={{ color: "#007cc3" }} />
                ) : (
                  <Tooltip title="Home" placement="right">
                    <HomeIcon sx={{ color: "#007cc3" }} />
                  </Tooltip>
                )}
                {/* {open?<HomeIcon sx={{ color: "#007cc3" }} />:<></>} */}
              </ListItemIcon>
              <ListItemText
                primary={"Home"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "black",
                }}
                // onMouseEnter={() => setChange7(true)}
                // onMouseLeave={() => setChange7(false)}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={(e) => handlePath(e, "/sites")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <AccountTreeIcon sx={{ color: "#007cc3" }} /> */}
                {open?<AccountTreeIcon sx={{ color: "#007cc3" }} />:<Tooltip title="Sites" placement="right"><AccountTreeIcon sx={{ color: "#007cc3" }} /></Tooltip>}
              </ListItemIcon>
              <ListItemText
                primary={"Sites"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "black",
                }}
                // onMouseEnter={() => setChange7(true)}
                // onMouseLeave={() => setChange7(false)}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={(e) => handlePath(e, "/service_avail")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <MiscellaneousServicesIcon sx={{ color: "#007cc3" }} /> */}
                {open?<MiscellaneousServicesIcon sx={{ color: "#007cc3" }} />:<Tooltip title="Services" placement="right"><MiscellaneousServicesIcon sx={{ color: "#007cc3" }} /></Tooltip>}
              </ListItemIcon>
              <ListItemText
                primary={"Services"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "black",
                }}
                // onMouseEnter={() => setChange7(true)}
                // onMouseLeave={() => setChange7(false)}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={(e) => handlePath(e, "/download_report")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <CloudDownloadIcon sx={{ color: "#007cc3" }} /> */}
                {open?<CloudDownloadIcon sx={{ color: "#007cc3" }} />:<Tooltip title="Download Report" placement="right"> <CloudDownloadIcon sx={{ color: "#007cc3" }} /></Tooltip>}
              </ListItemIcon>
              <ListItemText
                primary={"Download Report"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "black",
                }}
                // onMouseEnter={() => setChange7(true)}
                // onMouseLeave={() => setChange7(false)}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block", backgroundColor: show ? "#007cc3" : "" }}
            onClick={() => setshow(!show)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* <InventoryIcon sx={{ color: show ? "white" : "#007cc3" }} /> */}
                {open?<InventoryIcon sx={{ color: show?"white":"#007cc3" }} />:<Tooltip title="Purchase" placement="right"><InventoryIcon sx={{ color: show ? "white" : "#007cc3" }} /> </Tooltip>}
              </ListItemIcon>
              <ListItemText
                primary={"Purchase"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: show ? "white" : "black",
                }}
                // onMouseEnter={() => setChange7(true)}
                // onMouseLeave={() => setChange7(false)}
              />
            </ListItemButton>
          </ListItem>

          {show ? (
            <>
              {" "}
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={(e) => handlePath(e, "/purchase_order")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 4.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <LocalMallIcon sx={{ color: "#007cc3" }} /> */}
                    {open?<LocalMallIcon sx={{ color: "#007cc3" }} />:<Tooltip title="Purchase Order" placement="right"><LocalMallIcon sx={{ color: "#007cc3" }} /></Tooltip>}
                  </ListItemIcon>
                  <ListItemText
                    primary={"Purchase Order"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "black",
                    }}
                    // onMouseEnter={() => setChange7(true)}
                    // onMouseLeave={() => setChange7(false)}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={(e) => handlePath(e, "/invoices")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 4.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <ReceiptLongIcon sx={{ color: "#007cc3" }} /> */}
                    {open?<ReceiptLongIcon sx={{ color: "#007cc3" }} />:<Tooltip title="Invoices" placement= "right"><ReceiptLongIcon sx={{ color: "#007cc3" }} /></Tooltip>}
                  </ListItemIcon>
                  <ListItemText
                    primary={"Invoices"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "black",
                    }}
                    // onMouseEnter={() => setChange7(true)}
                    // onMouseLeave={() => setChange7(false)}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={(e) => handlePath(e, "/payments")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 4.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <PaymentsIcon sx={{ color: "#007cc3" }} /> */}
                    {open?<PaymentsIcon sx={{ color: "#007cc3" }} />:<Tooltip title="Payments" placement="right"><PaymentsIcon sx={{ color: "#007cc3" }} /></Tooltip>}
                  </ListItemIcon>
                  <ListItemText
                    primary={"Payments"}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "black",
                    }}
                    // onMouseEnter={() => setChange7(true)}
                    // onMouseLeave={() => setChange7(false)}
                  />
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <></>
          )}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={(e) => handlePath(e, "/query_list")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
               {open? <EventNoteIcon sx={{ color: "#007cc3" }} />:<Tooltip title="Query List" placement="right"><EventNoteIcon sx={{ color: "#007cc3" }} /></Tooltip>}
              </ListItemIcon>
              <ListItemText
                primary={"Query List"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "black",
                }}
                // onMouseEnter={() => setChange7(true)}
                // onMouseLeave={() => setChange7(false)}
              />
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Divider /> */}
    

      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* {purchase?<Purchase_order/>:<></>}
      {invoices?<Invoice/>:<></>}
      {payments?<Payments/>:<></>}
      <div style={{position:"absolute", bottom:"0px", left:"0px", right:"0px"}}>
                  <p id={styles.copyright}>
                  Copyright : Equinox Labs Pvt ltd-2023
                  </p>
              </div> */}
      </Box>




      <Add_query
        open={open2}
        handleOpen={handleOpen2}
        handleClose={handleClose2}
        query_check={query_check}
      />
      <Org_details 
              open={open3}
              handleOpen={handleOpen3}
              handleClose={handleClose3}
              data={profile_data}
      />

<Menu
        id="simple-menu"
        anchorEl={anchorEl2}
        open={Boolean(anchorEl2)}
        onClose={handleClose_kae}
        MenuListProps={{ onMouseLeave: handleClose_kae }}
        className={styles.menu_container}
      >
  
      <MenuItem sx={{overflow:"auto",borderBottom:"1px solid #d1d1d1",pointerEvents:"none"}} onClick={handleClose_kae}>
            <p><span>KAE Name</span> : {support_data[0]?.planner_name}</p>
    </MenuItem>


        <MenuItem sx={{overflow:"auto",borderBottom:"1px solid #d1d1d1",pointerEvents:"none"}} onClick={handleClose_kae}><p><span>Mobile</span> : {support_data[0]?.planner_number}</p></MenuItem>
        <MenuItem sx={{pointerEvents:"none"}} onClick={handleClose_kae}><p><span>Email</span> : {support_data[0]?.planner_email}</p></MenuItem> 
      </Menu> 



      <Menu
        id="simple-menu"
        anchorEl={anchorEl3}
        open={Boolean(anchorEl3)}
        onClose={handleClose_sales}
        MenuListProps={{ onMouseLeave: handleClose_sales }}
        className={styles.menu_container}
      >
  
      <MenuItem sx={{overflow:"auto",borderBottom:"1px solid #d1d1d1",pointerEvents:"none"}} onClick={handleClose_sales}>
            <p><span>Sales Person Name</span> : {support_data[0]?.salesperson_name}</p>
    </MenuItem>


        <MenuItem sx={{overflow:"auto",borderBottom:"1px solid #d1d1d1",pointerEvents:"none"}} onClick={handleClose_sales}><p><span>Mobile</span> : {support_data[0]?.salesperson_number}</p></MenuItem>
        <MenuItem sx={{pointerEvents:"none"}} onClick={handleClose_sales}><p><span>Email</span> : {support_data[0]?.salesperson_email}</p></MenuItem> 
      </Menu> 


    </Box>
  );
}


