import React, { useEffect, useState } from "react";
import Collection_tbl from "../Components/Collection_tbl";
import styles from "../Styles/Dashboard.module.css";
import Single_tab from "../Components/Single_tab";
import MenuAppBar from "../Components/Header";
import Drawer_customer from "../Components/Drawer";
import Swal from "sweetalert2";
import loader_img from "../Media/Business_chart.gif"
import axios from "axios";
import Footer from "../Components/footer";

function Download_report({ base_url }) {
  const storage = window.sessionStorage;
  let token = storage.getItem("token");
  let profile = JSON?.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");

  const [selected_flag, setSelected_Flag] = useState("");
  const [report_data, setReport_Data] = useState([]);
  const [file_path, setFile_Path] = useState("");
  const [report_sample, setReport_Sample] = useState([]);
  const [sample_name, setSample_Name] = useState("");

  const [report_load,setReport_Load] = useState(false)

  let support_details = JSON?.parse(storage.getItem("support_details"));
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  const data_check = [1, 2, 1, 1];

  const get_report_list = async (val) => {
    setReport_Load(true)
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    formData.append("category_name", val);
    // formData.append("year", Number(year));
    // formData.append("year", year == ""?2024:Number(year));
    axios({
      url: `${base_url}/customers-report-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setReport_Load(false)
        if (res?.data?.code == "200") {
          // setSchedule_Heat_Map_Data(res?.data?.data);


          setReport_Data(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setReport_Load(false)
        console.log(err);
      });
  };

  const get_tab_list = async()=>{
    setReport_Load(true)
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);

    // formData.append("year", year == ""?2024:Number(year));
    axios({
      url: `${base_url}/customers-report-tab-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setReport_Load(false)
        if (res?.data?.code == "200") {
          // setSchedule_Heat_Map_Data(res?.data?.data);
         
          setReport_Sample(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setReport_Load(false)
        console.log(err);
      });
  }

  // useEffect(() => {
  //   if (token != "" && token != null) {
  //     get_report_list();
  //   }
  // }, [sample_name]);


  useEffect(()=>{
    if (token != "" && token != null) {
      get_tab_list();
    }
  },[])

  return (
    <>
      {/* <MenuAppBar/> */}
      <Drawer_customer />
     {report_load?<div><img id={styles.loader} src={loader_img}/></div>: <div
        className="container-fluid"
        style={{ paddingLeft: "5em", marginTop: "-2rem" }}
      >
        {/* <div className='row mt-2'>
              <div className='row mb-2' id={styles.service_tab_contain}>
                {report_sample?.map((ele)=>(
                  <Single_tab ele={ele} selected_flag={selected_flag} setSelected_Flag={setSelected_Flag} setSample_Name={setSample_Name} />
                ))}
   
              </div>
         </div>

         <div className='row'>
               <div className='col-sm-12 col-md-7'>
                   <Collection_tbl  data={report_data} setFile_Path={setFile_Path} />
               </div>
               <div className='col-sm-12 col-md-5' id={styles.pdf_msg} style={{boxShadow:file_path == ""?"rgba(0, 0, 0, 0.35) 0px 5px 15px":""}}>
            {file_path == ""?<p style={{marginTop:"20%"}}>Please Select any line item to view report</p>: <iframe src={`${file_path}#toolbar=0`} id={styles.pdf_contain} frameborder="0">              
             </iframe>}
               </div>
         </div> */}

        <div className="row">
          <div className="col-sm-12 col-md-8">
            <div className="row" id={styles.download_header}>
              <h6>Download Report</h6>
            </div>
            <div className="row mt-3" id={styles.service_tab_contain}>
              {report_sample?.map((ele) => (
                <Single_tab
                  ele={ele}
                  selected_flag={selected_flag}
                  setSelected_Flag={setSelected_Flag}
                  setSample_Name={setSample_Name}
                  setFile_Path={setFile_Path}
                  get_report_list={get_report_list}
                />
              ))}
            </div>
            <div className="row mt-3">
              <Collection_tbl data={report_data} setFile_Path={setFile_Path}  selected_flag={selected_flag} />
            </div>
          </div>
          <div
            className="col-sm-12 col-md-4"
            id={styles.pdf_msg}
            style={{
              boxShadow:
                file_path == "" ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "",
            }}
          >
            {file_path == "" ? (
              <p style={{ marginTop: "20%" }}>
                Please Select any line item to view report
              </p>
            ) : (
              <iframe
                src={`${file_path}#toolbar=0`}
                id={styles.pdf_contain}
                frameborder="0"
              ></iframe>
            )}
          </div>
        </div>
      </div>}
      {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
                    <p id={styles.copyright}>


                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>

                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}
          <Footer/>
    </>
  );
}

export default Download_report;
